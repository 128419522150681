import React from 'react';

import Header from './Header/Header';
import Hoc from '../../hoc/Hoc';
import Footer from './Footer/Footer';
import Routes from '../../routes'





const Layout = () => {
    const path = window.location.pathname;  
    return (
        <Hoc>
            {['/emicalculator/terms-and-conditions', '/emicalculator/privacy-policy'].includes(path) ? <Routes />  :
                <>
                    <Header />           
                    <Routes />
                    <Footer />
                </>
            }
        </Hoc>
    );
}

export default Layout; 