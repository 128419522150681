import React, { Component } from "react";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
} from "mdbreact";
import onClickOutside from "react-onclickoutside";
import styles from "./Header.module.css";
import { IMGIX_PATH, S3_BUCKET_PATH } from "../../../BConfig";
import "./header.css";
import "mdbreact/dist/css/mdb.css";
import {
  MDBMainNav,
  MDBNavItemLink,
  MDBNavbarBrandLogo,
  DorpDownLink,
  MDBNavbarNavCustom,
  NavLinkRouter,
  LoanProductDropDown,
  PersonalDropDown,
  LoanDropDown,
  PersonalMobViewDropDown,
  MobFlexInline,
  PersonalLoan
} from "./skin";
const logo2 = IMGIX_PATH + "logo2_6IJ803Kx7.svg";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      Icon: "fa fa-bars",
    };
    this.onClickLink = this.onClickLink.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }

  handleClickOutside(e) {
    this.setState({
      isOpen: false,
      Icon: "fa fa-bars",
    });
    document.getElementById("navbarCollapse3").style.width = "0";
  }

  onClickLink() {
    this.setState({
      isOpen: false,
      Icon: "fa fa-bars",
    });
  }

  onClick = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      Icon: this.state.Icon === "fa fa-times" ? "fa fa-bars" : "fa fa-times",
    });
    document.getElementById("navbarCollapse3").style.width = "250px";
  };

  closeNav() {
    this.setState({
      isOpen: !this.state.isOpen,
      Icon: this.state.Icon === "fa fa-times" ? "fa fa-bars" : "fa fa-times",
    });
    document.getElementById("navbarCollapse3").style.width = "0";
  }


  handleHover = () => {
    this.state.isOpen
      ? this.setState({ isOpen: false })
      : this.setState({ isOpen: true });
  };


  render() {
    const { isOpen } = this.state;
    return (
      <MDBMainNav color="white" light expand="md" id="header-con">
        <MDBNavbarBrandLogo>
          <NavLinkRouter
            className="nav-link mr-auto"
            to="/"
            onClick={this.closeNav}
          >
            <img src={logo2} alt="logo" />
          </NavLinkRouter>
        </MDBNavbarBrandLogo>
        {window.screen.availWidth <= "1079" && (
          <button
            onClick={
              this.state.Icon === "fa fa-times" ? this.closeNav : this.onClick
            }
            className="mobNavToggler"
          >
            <i className={this.state.Icon} aria-hidden="true"></i>
          </button>
        )}
        <div
          id="navbarCollapse3"
          isopen={this.state.isOpen.toString()}
          navbar="true"
          className={
            window.screen.availWidth <= "1079" ? styles.sidenav : styles.mainNav
          }
        >
          <MDBNavbarNavCustom right>
            <MDBNavItemLink>
              <NavLinkRouter to="/lending-partner" onClick={this.closeNav}>
                Digital Lending Partners
              </NavLinkRouter>
            </MDBNavItemLink>
            <MDBNavItemLink>
              <NavLinkRouter to="/repay/login" onClick={this.closeNav}>
                Repayment
              </NavLinkRouter>
            </MDBNavItemLink>

            <MDBNavItemLink>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Policies & Codes
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <DorpDownLink
                    href={`${S3_BUCKET_PATH}/PoliciesAndCodes/Privacy+Policy.pdf`}
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Privacy Policy
                  </DorpDownLink>
                  <DorpDownLink
                    href="/terms-and-conditions"
                    onClick={this.closeNav}
                  >
                    Terms and Conditions
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/Corporate+Social+Responsibility+Policy.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Corporate Social Responsibility Policy
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/policies/Interest+Rate+Policy.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Interest Rate and Penal Charges Policy
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/policies/SOP-Release+of+Immovable+Property+Documents.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    SOP-Release of Immovable Property Documents
                  </DorpDownLink>
                  {/* <MDBDropdown dropright size="sm">
                    <MDBDropdownToggle nav caret>
                      Grievance Redressal policy
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                      <DorpDownLink
                        href={`${S3_BUCKET_PATH}/PoliciesAndCodes/Grievance+Redressal+Policy.pdf`}
                        onClick={this.closeNav}
                        target="_blank"
                      >
                        English
                      </DorpDownLink>
                      <DorpDownLink
                        href={`${S3_BUCKET_PATH}/policies/Grievance+Redressal+Policy-110822_Hindi.pdf`}
                        onClick={this.closeNav}
                        target="_blank"
                      >
                        Hindi
                      </DorpDownLink>
                      <DorpDownLink
                        href={`${S3_BUCKET_PATH}/policies/Grievance+Redressal+Policy-110822_Kannada.pdf`}
                        target="_blank"
                        onClick={this.closeNav}
                      >
                        Kannada
                      </DorpDownLink>
                    </MDBDropdownMenu>
                  </MDBDropdown> */}
                  <DorpDownLink
                    href={`${S3_BUCKET_PATH}/PoliciesAndCodes/Grievance+Redressal+Policy.pdf`}
                    onClick={this.closeNav}
                    target="_blank"
                  >
                    Grievance Redressal policy
                  </DorpDownLink>
                  <DorpDownLink
                    href="/grievance-redressal-policy-co-lender"
                    onClick={this.closeNav}
                  >
                     Grievance Redressal policy Co-Lender
                  </DorpDownLink>
                  <DorpDownLink href="/moratorium" onClick={this.closeNav}>
                    EMI Moratorium/Deferment Policy
                  </DorpDownLink>
                  <DorpDownLink
                    href={`${S3_BUCKET_PATH}/PoliciesAndCodes/Corporate+Governance+policy.pdf`}
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Corporate Governance Policy
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/policies/Vigil+Mechanism+Policy.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Vigilance Policy
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/policies/Nomination+and+Remuneration+Policy+%26+Succession+Policy.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Nomination & Remuneration Policy
                  </DorpDownLink>
                  {/* <DorpDownLink href="/familiarization-programme" onClick={this.closeNav}>Familiarization Programme</DorpDownLink> */}
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/policies/Related+Party+Transaction+Policy.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Related Party Transaction Policy
                  </DorpDownLink>
                  <DorpDownLink
                    href="/fair-practice-code"
                    onClick={this.closeNav}
                  >
                    Fair Practices Code
                  </DorpDownLink>
                  <DorpDownLink
                    href="/dlai-code-of-conduct"
                    onClick={this.closeNav}
                  >
                    DLAI code of conduct
                  </DorpDownLink>
                  <DorpDownLink
                    href="/ombudsman-scheme"
                    onClick={this.closeNav}
                  >
                    Ombudsman Scheme
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/policies/Resolution+Framework+Policy.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Resolution Framework Policy 2.0
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/Policy+on+Appointments+of+Auditors.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Policy on Appointment of Statutory Auditors
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/Policy+on+Preservation+of+Documents.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Policy for Preservation of Documents
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/Privacy+Policy+on+E-KYC.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Privacy Policy for E-KYC
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/Code+of+Fair+Disclosure+of+UPSI.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Code of Practices and Procedures for Fair Disclosure of UPSI
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/Code+of+conduct+DSADMA+Agents+DRA.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Code of Conduct DSA/DMA Agents/DRA
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/policies/FACE_Code+of+Conduct.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    FACE-Code of Conduct
                  </DorpDownLink>
                  <DorpDownLink
                    href={`${S3_BUCKET_PATH}/PoliciesAndCodes/DISCLOSURE+UNDER+GUIDELINES+ON+DEFAULT+LOSS+GUARANTEE.pdf`}
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Disclosure under Default Loss Guarantee Guidelines
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/Charter-Krazybee.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Charter of Customer Rights
                  </DorpDownLink>
                  <DorpDownLink
                    href="https://s3.ap-south-1.amazonaws.com/uploads.kbnbfc.in/Code+of+Conduct+for+Director+%26+Senior+Management.pdf"
                    target="_blank"
                    onClick={this.closeNav}
                  >
                    Code of Conduct for Director & Senior Management
                  </DorpDownLink>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItemLink>
            <MDBNavItemLink>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Loan Products
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <LoanProductDropDown>
                    <MDBDropdown dropright size="sm">
                      <PersonalDropDown
                        onMouseEnter={this.handleHover}
                        onMouseLeave={this.handleHover}
                      >
                        <MDBDropdownToggle nav caret>
                          Personal Loan
                        </MDBDropdownToggle>
                        <LoanDropDown Display={!isOpen ? "none" : "block"}>
                        <DorpDownLink
                            href="/loan-products/personal-loan-unsecured"
                            onClick={this.closeNav}
                          >
                            Personal Loan (Unsecured)
                          </DorpDownLink>
                          <DorpDownLink
                            href="/loan-products/flexi-personal-loan"
                            onClick={this.closeNav}
                          >
                            Flexi Personal Loan
                          </DorpDownLink>
                          <DorpDownLink
                            href="/loan-products/personal-loan-for-self-employed"
                            onClick={this.closeNav}
                          >
                            Personal Loan For Self-Employed
                          </DorpDownLink>
                          <DorpDownLink
                            href="/loan-products/personal-loan-for-salaried"
                            onClick={this.closeNav}
                          >
                            Personal Loan For Salaried
                          </DorpDownLink>
                          <DorpDownLink
                            href="/loan-products/top-up-loan"
                            onClick={this.closeNav}
                          >
                           Top-up Loan
                          </DorpDownLink>
                          <DorpDownLink
                            href="/loan-products/purchase-on-emi"
                            onClick={this.closeNav}
                          >
                            Purchase On EMI
                          </DorpDownLink>
                        </LoanDropDown>
                      </PersonalDropDown>

                      <PersonalMobViewDropDown>
                        <MobFlexInline>
                        <MDBDropdownToggle nav caret>
                        Personal Loan
                       </MDBDropdownToggle>
                        </MobFlexInline>
                        <MDBDropdownMenu>
                        <DorpDownLink
                            href="/loan-products/personal-loan-unsecured"
                            onClick={this.closeNav}
                          >
                            Personal Loan (Unsecured)
                          </DorpDownLink>
                          <DorpDownLink
                            href="/loan-products/flexi-personal-loan"
                            onClick={this.closeNav}
                          >
                            Flexi Personal Loan
                          </DorpDownLink>
                          <DorpDownLink
                            href="/loan-products/personal-loan-for-self-employed"
                            onClick={this.closeNav}
                          >
                            Personal Loan For Self-Employed
                          </DorpDownLink>
                          <DorpDownLink
                            href="/loan-products/personal-loan-for-salaried"
                            onClick={this.closeNav}
                          >
                            Personal Loan For Salaried
                          </DorpDownLink>
                          <DorpDownLink
                            href="/loan-products/top-up-loan"
                            onClick={this.closeNav}
                          >
                           Top-up Loan
                          </DorpDownLink>
                          <DorpDownLink
                            href="/loan-products/purchase-on-emi"
                            onClick={this.closeNav}
                          >
                            Purchase On EMI
                          </DorpDownLink>
                        </MDBDropdownMenu>
                      </PersonalMobViewDropDown>

                      <DorpDownLink
                        href="/loan-products/salary-on-Demand"
                        onClick={this.closeNav}
                      >
                        Salary on Demand
                      </DorpDownLink>

                      <DorpDownLink
                        href="/loan-products/business-loan"
                        onClick={this.closeNav}
                      >
                        Business Loan
                      </DorpDownLink>
                      <DorpDownLink
                        href="/loan-products/loan-against-property"
                        onClick={this.closeNav}
                      >
                        Loan against Property
                      </DorpDownLink>
                      <DorpDownLink
                      href="/documentation"
                        onClick={this.closeNav}
                      >
                        Documentation
                      </DorpDownLink>
                      {/* <DorpDownLink
                      href="/loan-products/gold-loan"
                      onClick={this.closeNav}
                      >
                        Gold Loan
                      </DorpDownLink> */}
                      <DorpDownLink
                      href="/loan-products/two-wheeler-loan"
                      onClick={this.closeNav}
                      >
                        Two wheeler Loan
                      </DorpDownLink>
                    </MDBDropdown>
                  </LoanProductDropDown>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItemLink>

            <MDBNavItemLink>
              <NavLinkRouter to="/investor-relations" onClick={this.closeNav}>
                Investor&nbsp;Relations
              </NavLinkRouter>
            </MDBNavItemLink>
          </MDBNavbarNavCustom>
        </div>
      </MDBMainNav>
    );
  }
}

export default onClickOutside(Header);
